<template>
  <div :is="organizationData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="organizationData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
    </b-alert>

    <template v-if="organizationData">
      <div class="cardTableProject">
        <b-card>
          <h2 class="mb-2">
            {{ $t("message.Organization.viewOrganization") }}
          </h2>

          <b-row cols="12">
            <b-col md="4" class="mt-1">
              <b-form-group label-for="nameOpportunities">
                <template v-slot:label>
                  {{ $t("message.Organization.nameOfYourCompany") }}
                </template>
                <b-form-input
                  type="text"
                  v-model="organizationData.company"
                  readonly
                />
              </b-form-group>
            </b-col>

            <!-- customers -->
            <b-col md="4" class="mt-1">
              <b-form-group label-for="costumer">
                <template v-slot:label>
                  {{ $t("message.Organization.emailOfCompany") }}
                </template>
                <b-form-input
                  readonly
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="organizationData.emailCompany"
                  type="email"
                />
              </b-form-group>
            </b-col>

            <b-col md="2" class="mt-1">
              <b-form-group label="CNPJ" label-for="startOpportunities">
                <b-form-input
                  readonly
                  v-model="organizationData.cnpj"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </b-form-group>
            </b-col>

            <b-col md="2" class="mt-1">
              <b-form-group label-for="startOpportunities">
                <template v-slot:label>
                  {{ $t("message.Organization.phone") }}
                </template>
                <b-form-input readonly v-model="organizationData.phone" />
              </b-form-group>
            </b-col>

            <!-- end -->
            <b-col md="4">
              <b-form-group label-for="opportunityDate">
                <template v-slot:label>
                  {{ $t("message.Organization.fullResponsible") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.responsiblePerson"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.occupation") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.occupation"
                  type="text"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.stateOfProcess") }}
                </template>
                <b-form-input readonly v-model="organizationData.status" />
              </b-form-group>
            </b-col>

            <!-- adress -->
          </b-row>
          <b-row cols="12">
            <b-col md="4">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.adress") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.adress"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.district") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.district"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.city") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.city"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.Organization.zipCode") }}
                </template>
                <b-form-input
                  readonly
                  v-model="organizationData.zipCode"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="UF" label-for="value">
                <b-form-input
                  readonly
                  v-model="organizationData.uf"
                  type="text"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <br />
          <h5>Dados</h5>
          <br />

          <b-row>
            <b-col md="3">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("dataLimit") }}
                </template>
                <b-form-input readonly v-model="dataLimitMb" type="text" />
              </b-form-group>
            </b-col>
          </b-row>

          <br />
          <h5>Sistema</h5>
          <br />

          <b-row>
            <b-col md="12">
              <b-form-group label-for="value">
                <template v-slot:label>
                  Organização não apartada (Vinculada com root global do
                  sistema)
                </template>
                <b-form-checkbox v-model="organizationData.rootControl" disabled>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <!--<b-row>
            <b-col>
              <div class="text-right mt-2 mr-0 mb-2">
                <b-button
                  variant="primary"
                  :to="{ name: 'apps-organization-details-edit' }"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
              </div>
            </b-col>
          </b-row>-->
        </b-card>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BModal,
  VBModal,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BFormCheckbox,
} from "bootstrap-vue";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from "@axios";
import URL_API from "@core/utils/env";
import AccessControl from "@core/utils/access-control";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCardGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    flatPickr,
    BFormDatepicker,
    BModal,
    BFormCheckbox,
  },
  directives: {
    "b-modal": VBModal,
  },

  data() {
    return {
      userData: store.state.user.userData,
      dataLimitMb: "",
      organizationData: {},
    };
  },

  async created() {
    await this.getData();
  },

  methods: {
    restrictions(value) {
      return AccessControl(value);
    },

    async getData() {
      await axios({
        method: "get",
        url: `${URL_API}organization/tenant/${this.userData.tenant}`,
        headers: getHeader(store.state.user.userData),
      })
        .then((response) => {
          this.organizationData = response.data;
          if (this.organizationData.dataLimit != null) {
            var sizeInMB = (
              this.organizationData.dataLimit /
              (1024 * 1024)
            ).toFixed(2);
            this.dataLimitMb = sizeInMB;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            organizationData.value = undefined;
          }
        });
    },
  },
};
</script>

<style>
.widthTable {
  width: 100%;
}

.widthTd {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .cardTableProject {
    width: 100%;
  }

  .rTable td {
    display: flex;
    flex-direction: column;
  }
  .rTableWidth {
    width: 100%;
  }

  .rButton {
    margin-top: 3rem;
  }
}
</style>
